.markercluster-map {
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  height: 100vh;
}

.map {
  width: 100%;
  height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&display=swap');
* {
  font-family: 'Raleway', sans-serif;
  box-sizing: border-box;
}

html,
body {
  padding: 0 !important;
  margin: 0;
}

a {
  color: #b18856 !important;
  text-decoration: none;
  font-size: 1rem;
}

a:active,
a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
  outline-style: none;
}

.container {
  display: flex;
  height: 100vh;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.button-coffee {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 998;
}

.telegram {
  position: absolute;
  bottom: 1.1rem;
  left: 155px;
  z-index: 998;
  width: 50px;
  margin-left: 10px;
}

.map {
  width: 100%;
  height: 100%;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

h1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
}

.logo {
  background-color: burlywood;
  padding: 10px;
  font-weight: 500;
  font-size: 1rem;
  color: #194850;
  border-radius: 10px;
  font-family: 'Fredoka', sans-serif;
  z-index: 1000;
}

.search {
  position: absolute;
  top: 1rem;
  right: 25px;
  width: 100%;
  max-width: 25rem;
  z-index: 1;
}

.search input {
  padding: 0.6rem;
  padding-left: 1rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 15px;
  border-style: none;
  -webkit-box-shadow: 3px 3px 18px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 3px 18px 0px rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
}

.search input:focus-visible {
  outline: none;
}

[data-reach-combobox-popover] {
  border: none !important;
  margin-top: -10px;
  font-size: 100% !important;
  font-weight: 400 !important;
  z-index: 1;
  border-radius: 15px;
}

[data-reach-combobox-popover] li {
  list-style: none;
  font-weight: 300;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 15px;
}

#st-2 {
  position: absolute !important;
}

.locate {
  position: absolute;
  bottom: 1rem;
  right: 5px;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}

.infoBox {
  padding: 0.7rem;
  text-align: center;
  padding-top: 20px;
}

.gm-style-iw {
  text-align: center;
}

.swal2-styled.swal2-confirm {
  background-color: #bf915c !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(197 159 115) !important;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }

  .search {
    max-width: 12rem;
    right: 15px;
  }

  .locate {
    bottom: 2.8rem;
  }

  .button-coffee {
    bottom: 2.8rem;
  }

  .telegram {
    bottom: 2.9rem;
  }
  #st-2 {
    margin-bottom: 0 !important;
  }
  #st-2 .st-btn {
    height: auto;
    border-right: white;
    border-right-style: solid;
    border-right-width: 0.1px;
  }
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-geosearch-bar {
  right: 19px;
  position: absolute;
  max-width: calc(100% - 180px);
}

.leaflet-touch .leaflet-geosearch-bar form {
  padding: 0.2rem;
  padding-left: 1rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 15px;
  border-style: none;
  -webkit-box-shadow: 3px 3px 18px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 3px 18px 0px rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  margin: 0.5rem auto 0;
}

.leaflet-touch .leaflet-control-geosearch a.reset {
  border-radius: 15px;
}

.marker-cluster-custom {
  line-height: 45px;
  font-size: 0.7rem;
  font-weight: 700;
  color: white;
  background-image: url('large-icon.png');
  background-size: 50px 50px;
  text-align: center;
  z-index: 500;
}

.marker-cluster-custom2 {
  line-height: 35px;
  font-size: 0.7rem;
  font-weight: 700;
  color: white;
  background-image: url('medium-icon.png');
  text-align: center;
  background-size: 40px 40px;
  z-index: 400;
}

.marker-cluster-custom3 {
  line-height: 25px;
  font-size: 0.5rem;
  font-weight: 700;
  color: white;
  background-image: url('small-icon.png');
  background-size: 30px 30px;
  text-align: center;
  z-index: 300;
}

.report-problem {
  font-size: 0.7rem;
  line-height: 0px;
  position: absolute;
  right: 0;
  margin-right: 32px;
  margin-top: 3px;
}

.leaflet-popup-content {
  margin: 0px 6px;
}

.leaflet-container a.leaflet-popup-close-button {
  margin-right: 5px;
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  font-size: 0.9rem;
  font-weight: 700;
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  bottom: 20px;
  margin-left: -100px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;

  display: none; /* hide by default */
}

.tooltip:hover:before {
  display: block;
}
